import React, { useState, useRef, useEffect } from "react";

import "./ViewProduct.css";
import axios from "axios";
import Breadcrumbs from "../../../SubComponent/Breadcrumbs";
import Footer from "../../../Footer/Footer";
import ImageSlider from "../../Modals/ImageSlider";
import VideoModal from "../../Modals/VideoModal";
import PhoneModal from "../../Modals/PhoneModal";
import DateModal from "../../Modals/DateModal";
import Slider from "react-slick";
import TenureSlider from "../../../../helpers/TenureSlider";
import CheckPurple from "../../../SubComponent/CheckPurple";
import CallCenterIcon from "../../../SubComponent/CallCenterIcon";
import PricesCompetitive from "../../../SubComponent/PricesCompetitive";
import InvoiceIcon from "../../../SubComponent/invoiceIcon";
import DetailedInspection from "../../Modals/DetailedInspection";
import {
  locationIcon,
  framre3dIcon,
  callCentreIcon,
  heartIcon,
  botIcon,
  infoIcon,
  smallpaymentIcon,
  ownershipIcon,
  flexibilityIcon,
  taxIcon,
  conserveIcon,
  maintenanceIcon,
  leftArrowIcon,
  rightArrowIcon,
  starIcon,
  visualIcon,
  spareIcon,
  skipIcon,
  closeIcon,
} from "../../../../helpers/Icons";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoginModel from "../../../Authentication/LoginModel/LoginModel";
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../../../OrigaExtentionAPI/mutations";
import useWishListAddOrUpdate from "../../../SubComponent/useWishListAddOrUpdate";
import Cart from "../../../SubComponent/Cart/Cart";
import { gql } from "graphql-tag";
import MachinePurchaseModal from "./QuationModel";
import Loader from "../../../SubComponent/Loader";
import QuotationPopup from "./QuotationPopup";
import DeliveryLocation from "../../Modals/DeliveryLocation";
import { Helmet } from "react-helmet";

const CREATE_CONTACT_US = gql`
  mutation CreateConatctUs($contactusinput: ContactUsInput!) {
    createContactUs(contactusinput: $contactusinput) {
      contactus {
        id
        firstname
        lastname
        emailid
        reasonId {
          id
          reasonTitle
          reasonDescription
        }
        queryDescription
      }
      message
      success
    }
  }
`;
/*create a new contact us entry with specified input data
It is supposed create a contact us form  to recieve a message and sucess  */

const client = secondClient; //apollo client instance used for graphql operations
const ViewSpareProduct = () => {
  const [endQuantity, setEndQuantity] = useState();
  const [showPopup, setShowPopup] = useState(false);
    const [showThanksPopup, setShowThanksPopup] = useState(false);
  const[showDeliveryPopup,setShowDeliveryPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const location = useLocation(); //to access the current location
  const queryParams = new URLSearchParams(location.search); //to pass the query parameters to the url
  const navigate = useNavigate();
  const { handleClick } = Cart();
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { group_id, objectID } = location.state || {};
  const group_id_url =queryParams.get('group_id');
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [deliveryAvailable, setDeliveryAvailable] = useState(true);
  const [seeMore, setSeeMore] = useState(false);


  const calculateDiscount = (mrp, price) => {
    console.log("Mrp",mrp);
    console.log("Price : ",price);
    if (mrp > 0) {
      return Math.ceil(((mrp - price) / mrp) * 100);
    }
    return 0; // Return 0 if MRP is not valid
  };

  useEffect(() => {
    const storedLocation = localStorage.getItem("deliveryLocation");
    if (storedLocation) {
      checkDeliveryAvailability(storedLocation);
    } else {
      // You may handle cases when there is no stored location
      setDeliveryAvailable(true); 
    }
  }, []);

  const handleSeeMore = (status) => {
    setSeeMore(status);
    document.body.classList.toggle('no-overflow', status);
};



const checkDeliveryAvailability = async (pincode) => {
  try {
      const response = await fetch('https://ompextension.origa.market/api/getdeliverypincode', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pincode }),
      });
      const data = await response.json();
      setDeliveryAvailable(data.available_status === 'available');
      if (data.available_status === 'available') {
          setDeliveryLocation(pincode);
          localStorage.setItem("deliveryLocation", pincode);
      } else {
          setShowDeliveryPopup(false);
          // alert("Deliver location not available : ")
      }
  } catch (error) {
      console.error("Error checking delivery availability:", error);
      setDeliveryAvailable(false);
  }
};

const onGetPinCodeHandler = (pin) => {
  checkDeliveryAvailability(pin);
};
  useEffect(() => {
window.scrollTo(0,0)
  },[])
  // console.log(`Received Group Id : ${group_id}`);
  // console.log(`Received Item Id : ${objectID}`);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const productId = queryParams.get("id"); //to get the id from url
  const groupId = queryParams.get("group_id"); //to get the id from url
  // console.log("Received productId :", productId);
  // console.log("Received Group Id :", groupId);

  // const handleQuantityClick = (quantity) => {
  //   setSelectedQuantity(quantity);
  //   console.log("Product Details : ", productDETAILS);
  //   console.log("Product Details");
  // };

  const [buymachineId, setBuymachineId] = useState(0);
  const [QuationModel, SetQuationModel] = useState(false);
  const storedLocation = localStorage.getItem("deliveryLocation");
  const [activeTab, setActiveTab] = useState("Basic Information");
  const [showInfo, setShowinfo] = useState(false);
  const [financeTab, setFinanceTab] = useState("lease");
  const sliderBenefitsRef = useRef(null);
  const [loading, setLoading] = useState(true);
  if (storedLocation && !deliveryLocation) {
    setDeliveryLocation(storedLocation);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedLocation = localStorage.getItem("deliveryLocation");
    if (storedLocation && !deliveryLocation) {
        setDeliveryLocation(storedLocation);
        checkDeliveryAvailability(storedLocation);
    }
}, []);
  const [isFill, setIsFill] = useState("#FFF");
  const [isStorke, setIsStorke] = useState("#000");
  const [productPage, setProductPage] = useState(""); // product-page || below-5-lakhs || above-5-lakhs
  const [showModal, setShowModal] = useState(false);
  const [productDETAILS, setProductDETAILS] = useState({});
  const [infokey, setinfokey] = useState(0);
  const prldvsjice = productDETAILS?.[infokey]?.saleprice * selectedQuantity;
  const [VarientAttributes, setVarientAttributes] = useState([]);
  const [loginPortal, setLoginPortal] = useState(false);

  const sliderProductRef = useRef(null);
  const [pricebtn, setPricebtn] = useState(!!localStorage.getItem("id"));
  const { onWishlistHandler, heartColor } = useWishListAddOrUpdate();

  const closePopup2 = () => {
    setShowPopup2(false);
    navigate("/store");
  };





  const onShowPortalforCart = () => {
    const loggedin = !!localStorage.getItem("userToken");
    if (loggedin) {
      navigate("/cartpage");
      return;
    } else {
      setLoginPortal(true);
    }
  };




  const productName = productDETAILS?.[infokey]?.name;

  const productBrand = productDETAILS[0]?.brand || "N/A";
  const [isDisabled, setIsDisabled] = useState(false);
  const productImage =
    productDETAILS[0]?.images?.productImages[0]?.url || "N/A";
  const salePrice = productDETAILS[0]?.saleprice || 0;
  const productIdtoCart = productDETAILS?.[infokey]?.id;

  const getBucketId = (selectedQuantity) => {
    const priceList = selectedProduct?.price_list || [];

    const bucket = priceList.find(
      (bucket) =>
        bucket.start_quantity <= selectedQuantity &&
        (bucket.end_quantity === null ||
          bucket.end_quantity >= selectedQuantity)
    );

    return bucket ? bucket.bucket_id : null;
  };

  const handleCart = async () => {
    const productIdtoCart = productDETAILS?.[infokey]?.id;
    const productName = productDETAILS?.[infokey]?.name;
    const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
    const productimage =
      productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
    const salesPrice = productDETAILS?.[infokey]?.saleprice;

    const bucketId = getBucketId(selectedQuantity);

    const quantityprice = quantity;
    const quantitypricevc = selectedQuantity;
    // console.log("Selected Quantity : --------------> ",quantitypricevc)


   
  
  

    
    const totalCartPriceof = bucketPrice * bucketSize * quantitypricevc;
    console.log(
      "Price of the calculated bucket totlal Price : ----->",
      totalCartPriceof
    );

    const loggedin = localStorage.getItem("userToken");
    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    console.log(
      "before - - Cart Items   see what all items are present  :   : ---------------------------------------------->",
      cartItems
    );
    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");
   // Find if product already exists in the cart
  const existingCartItemIndex = cartItems.findIndex(
    (item) => item.productId === productIdtoCart
  );

  if (existingCartItemIndex !== -1) {
    // Product exists in the cart, increase the quantity by selectedQuantity (e.g., 4)
    cartItems[existingCartItemIndex].quantity += quantitypricevc; // Increment by the selected quantity
    cartItems[existingCartItemIndex].totalCartPriceocvf =
      bucketPrice * bucketSize * cartItems[existingCartItemIndex].quantity; // Update total price based on new quantity
  } else {
    // Product doesn't exist, add it to the cart
    cartItems.push({
      productId: productIdtoCart,
      productName: productName,
      productBrand: productbrand,
      productImage: productimage,
      salePrice: salesPrice,
      quantity: quantitypricevc,
      bucketId: bucketId,
      bucketSize: bucketSize,
      bucketPrice: bucketPrice,
      totalCartPriceocvf: totalCartPriceof,
      endQuantity: endQuantity,
    });
  }

    if (loggedin) {
      // If logged in, send the item to the API
      // console.log(
      //   "Now Inside  cartItems --------============-------------------------------->",
      //   cartItems
      // );
      try {
        console.log(
          "Now herrrrrrrreeeeeeeeee --------============-------------------------------->",
          cartItems
        );
        var itemList = [];
        let bucketId = null;
        for (var item of cartItems) {
          // Iterate through localCartItems
          var obj = {};
          obj["item_id"] = item.productId;
          console.log("Product ID :  ------------------>", productId);
          obj["quantity"] = quantitypricevc; // Set quantity to 1
          console.log("item_id:", obj["item_id"], "quantity:", obj["quantity"]); // Log item_id and quantity
          obj["bucket_id"] = item["bucketId"]; // Use bucket_id

          console.log(
            "Bucketid in localCartitems :  --------------> ",
            item["bucketId"]
          );

          if (bucketId === null) {
            bucketId = item["bucketId"]; // Set bucketId if it's null
          }

          itemList.push(obj);
        }
        console.log("itemList :: ", itemList);
        await fetch(
          "https://ompextension.origa.market/sparesapi/create_order",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              items: itemList,
              bucket_id: bucketId,
              ompuserId, // Get the id from the localStorage
              customer_first_name,
              customer_last_name,
              customer_contact_no,
              order_status: "Draft",
              order_ref_number: "",
              customer_email,
              input: {
                city: "",
                cityArea: "",
                companyName: "",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "  ",
                phone: "",
                postalCode: "",
                streetAddress1: " ",
                streetAddress2: "",
              },
              businessaddress: {
                city: "",
                cityArea: "",
                companyName: " ",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "",
                phone: "",
                postalCode: "",
                streetAddress1: "",
                streetAddress2: "",
              },
              gst_no: "",
            }),
          }
        );
        console.log(`Item added to API cart. Product ID: ${productIdtoCart}`);
      } catch (error) {
        console.error("Error adding item to API cart:", error);
      }
    } else {
      // If not logged in, save to localStorage
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }

    // setTimeout(() => {
    //   window.location.reload();
    //   navigate("/cartpage");
    // }, 100);

    setLoading(true);
    setTimeout(() => {
      window.location.reload();
      // navigate("/cartpage");
    }, 500);
    // navigate("/cartpagetotal");
  };

  const handleRaiseAQuotation = () => {
    const loggedin = localStorage.getItem("userToken");
    // console.log("Inside HandleRaiseQuotation : ", loggedin);

    const productIdtoCart = productDETAILS?.[infokey]?.id;
    console.log("Inside HandleRaiseQuotation productIdtoCart : ", productIdtoCart);

    const productName = productDETAILS?.[infokey]?.name;
    console.log("Inside HandleRaiseQuotation productName : ", productName);

    const productPrice = productDETAILS?.[infokey]?.price;
    console.log("Inside HandleRaiseQuotation productPrice : ", productPrice);

    const productImage =
    productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";

    const priceList = productDETAILS?.[infokey]?.price_list || [];
    console.log("Price list: ", priceList);
    
  // Declare bucket-related variables outside the if block
  let bucketId = null;
  let bucketSize = null;
  let firstPrice = null;

  if (priceList.length > 0) {
      const firstPriceInfo = priceList[0];
      // Store the first bucket ID, bucket size, and price in variables
      bucketId = firstPriceInfo.bucket_id;
      bucketSize = firstPriceInfo.bucket_size;
      firstPrice = firstPriceInfo.price;

      console.log("First bucket ID:", bucketId);
      console.log("First bucket size:", bucketSize);
      console.log("First price:", firstPrice);
  }

    if (loggedin) {
      setShowPopup(true); // Show popup to ask for quantity
      setProductDETAILS({
        id: productDETAILS?.[infokey]?.id,
        name: productName,
        price: firstPrice,
        image: productImage,
      });
    } else {
      setLoginPortal(true);
    }
  };
  const handleSubmit = async ({ productId, quantity, bucketId }) => {
    console.log("Product Id:", productId);
    console.log("Quantity:", quantity);
    console.log("Bucket ID:", bucketId);
  
    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");
  
    const requestBody = {
      items: [
        {
          item_id: productId,       // Product ID from QuotationPopup
          quantity: parseInt(quantity), // Selected quantity from QuotationPopup
          bucket_id: bucketId,      // Selected bucket ID from QuotationPopup
        },
      ],
      ompuserId,
      customer_first_name,
      customer_last_name,
      customer_contact_no,
      order_status: "Draft",
      order_ref_number: "",
      customer_email,
      input: {
        city: "",
        cityArea: "",
        companyName: "",
        country: "",
        countryArea: "",
        firstName: "",
        lastName: "",
        phone: "",
        postalCode: "",
        streetAddress1: "",
        streetAddress2: "",
      },
      businessaddress: {
        city: "",
        cityArea: "",
        companyName: "",
        country: "",
        countryArea: "",
        firstName: "",
        lastName: "",
        phone: "",
        postalCode: "",
        streetAddress1: "",
        streetAddress2: "",
      },
      gst_no: "",
    };
  
    console.log("Request Body:", requestBody);
  
    try {
      const response = await fetch("https://ompextension.origa.market/api/request_quotation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
  
      const data = await response.json();
      if (data.message === "Quotation request saved successfully!") {
        setShowPopup(false);
        setShowThanksPopup(true);
        setTimeout(() => {
          setShowThanksPopup(false);
          navigate("/store");
          window.location.reload();
        }, 4000);
      } else {
        alert("Failed to raise a quotation.");
      }
    } catch (error) {
      console.error("Error submitting quotation:", error);
      alert("Error submitting quotation.");
    }
  };
  

  const availableStock = productDETAILS?.[infokey]?.available_stock || 0;
  const [quantity, setQuantity] = useState(1);

  const handleDecreaseQuantity = (productIdtoCart, quantity) => {
    setQuantity((prevQuantity) => {
      const newQuantity = Math.max(prevQuantity - 1, 1);
      console.log(
        `Product ID: ${productIdtoCart}, New Quantity: ${newQuantity}`
      );
      return newQuantity;
    });
  };

  const handleIncreaseQuantity = () => {
    const productName = productDETAILS?.[infokey]?.name;
    const availableStock = productDETAILS?.[infokey]?.available_stock || 0;

    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + 1;
      if (newQuantity > availableStock) {
        console.log(
          `Cannot increase quantity. Only ${availableStock} items available in stock.`
        );
        return prevQuantity;
      }
      console.log(
        `Product ID: ${productIdtoCart}, Product Name: ${productName}, New Quantity: ${newQuantity}`
      );
      return newQuantity;
    });
  };

  const ompuserId = localStorage.getItem("id");
  const customer_first_name = localStorage.getItem("firstName");
  const customer_last_name = localStorage.getItem("lastName");
  const customer_contact_no = localStorage.getItem("number");
  const customer_email = localStorage.getItem("emailId");
  const product = [
    {
      is_header: true,
      product_name: productDETAILS?.machine_details?.data?.product?.name,
      shortlist: 200,
    }, //name of the product that is fetched from the ProductDetails
    {
      is_product_images: true,
      images: productDETAILS?.machine_details?.data?.product?.medias,
    },
    {
      is_product_info: true,
      tabs_section: [{ is_tabs_section: true }],
      product_detail: [
        {
          is_product_detail: true,
          year_purchase: 2019,
          machine_location: "Andheri Mumbai",
        },
      ],
      price_section: [
        {
          is_price_section: false,
          heading: "Looking to purchase products in bulk?",
          desc: "Purchase item in bulk quantity",
          desc1: "Get at best price for your business",
          btn_text: "Get a Quote",
        },
      ],
      token_section: [
        {
          is_token_section: true,
          heading: `ORIGA Benefits`,
          desc: "Competitive Prices.",
          desc1: "Response within 24 hrs.",
          desc2: "GST Invoice Available",
          btn_text: "Pay Token",
          extre_info:
            "If you are interested to purchase the machine and wish to block it so that no one else can purchase it you would need to pay a non refundable token. This will block the machine for 7 days, in this period you would need to either complete or finance the rest of the payment.",
        },
      ],
    },

    {
      is_benefits: false,
      head: [
        {
          heading: "Product Benefits",
          desc: "From Machines to tools to finance everything you need in one place",
          btn_text: "View Detailed Report",
        },
      ],
      benefits_items: [
        {
          id: 1,
          ratio: "04/05",
          label: "Good",
          color: "#D6F518",
          percent: 58,
          title: "External Visual Condition",
          extre_info:
            "The external Machine Condition is rated as 4/5 which means there are barely a few dents and scratches.",
        },
        {
          id: 2,
          ratio: "05/05",
          label: "Great",
          color: "#077D55",
          percent: 100,
          title: "Static Geometric Test",
          extre_info:
            "The external Machine Condition is rated as 4/5 which means there are barely a few dents and scratches.",
        },
        {
          id: 3,
          ratio: "02/05",
          label: "Workable",
          color: "#E86427",
          percent: 28,
          title: "Electric & Pneumatic Condition",
          extre_info:
            "The external Machine Condition is rated as 4/5 which means there are barely a few dents and scratches.",
        },
        {
          id: 4,
          ratio: "03/05",
          label: "Fair",
          color: "#F6CF41",
          percent: 42,
          title: "Machine Usage History",
          extre_info:
            "The external Machine Condition is rated as 4/5 which means there are barely a few dents and scratches.",
        },
      ],
    },
    {
      is_benefits_layout_2: true,
      head: [
        {
          heading: "Product Benefits",
          desc: "From Machines to tools to finance everything you need in one place",
          btn_text: "View Detailed Report",
        },
      ],
      benefits_layout_2_items: [
        {
          title: "Visual Inspection Remarks",
          desc: "Table T slot broken Normal wear and tear due to ageing and uses",
        },
        {
          title: "Machine Dynamic Remarks",
          desc: "Origa will provide servicing for your machine so you don’t need to worry about anything...",
        },
        {
          title: "Machine Static Geometrical Test",
          desc: "Spindle Runout :- 3 micron TIR with needs dial gauge Axis Backlash :-X axis 10 microns,Y axis 5 microns, Z axis 5 microns",
        },
        {
          title: "Electrical & Pneumatic Condition",
          desc: "Origa can provide a lease and loan to enable you to purchase the machine you need",
        },
      ],
    },
  ];
  const options = {
    autoplay: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 479, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  const BenefitsOptions = {
    autoplay: false,
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const styleHandle = (value, color) => {
    return {
      "--percent": `${value}`,
      stroke: color,
    };
  };
  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };
  const handleFinanceTab = (tab) => {
    setFinanceTab(tab);
  };
  const nextSliderBenefitsRef = () => {
    sliderBenefitsRef.current.slickNext();
  };
  const previousSliderBenefitsRef = () => {
    sliderBenefitsRef.current.slickPrev();
  };
  const isProductPriceMore = () => {
    let machinePrice = productDETAILS?.sales_rate;
    if (parseInt(machinePrice) > 1) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { groupId:  group_id_url, itemId: "" },
        });

        console.log("API Response:", data);

        let productData = data?.inventoryItem?.response;

        if (Array.isArray(productData)) {
          setProductDETAILS(productData);

          const selectedIndex = productData.findIndex(
            (item) => item.id === objectID
          );
          if (selectedIndex !== -1) {
            setinfokey(selectedIndex);
            setSelectedItemId(objectID);
          } else {
            setinfokey(0);
            setSelectedItemId(productData[0]?.id);
          }
        } else {
          console.error("Unexpected API response structure");
          setProductDETAILS([]);
        }

        setLoading(false);
        updateFormattedPrice();
      } catch (error) {
        console.error("Error fetching product details:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [group_id, objectID]);
  const onShowPortal = async () => {
    const loggedin = !!localStorage.getItem("userToken");
    if (loggedin) {
      //If loggedin is true
      const firstname = localStorage.getItem("firstName");
      const lastName = localStorage.getItem("lastName");
      const email = localStorage.getItem("emailId");
      const phonenumber = localStorage.getItem("number");
      try {
        setLoading(true);
        const { data } = await client.mutate({
          //it sends a message to the people who made the app
          mutation: CREATE_CONTACT_US,
          variables: {
            contactusinput: {
              //these are the details that are sent with the message
              firstname: firstname,
              lastname: lastName,
              emailid: email,
              reasonid: 5,
              queryDescription: `Product:${productDETAILS?.name}, ProductId:${productDETAILS?.id}`,
              phonenumber: phonenumber,
            },
          },
        });
        console.log("API Response==>", data);
        setLoading(false);
        SetQuationModel(true);
      } catch (error) {
        setLoading(false);
        console.error("API Error==>", error.message);
      }
    } else {
      setLoginPortal(true);
    }
  };

  const onHidePortal = () => {
    setLoginPortal(false);
    //window.location.reload();
  };
  useEffect(() => {
    let search = window.location.search.replace("?type=", ""); //search for the url starts with type
    if (search == "") {
      //if it doesn't find anything after type= it decides to show product-page0
      search = "product-page";
    }
    setProductPage(search);
  }, []);

  const updateFormattedPrice = () => {
    //is used to update how the price of a product is displayed on a webpage
    setProductDETAILS((prevDetails) => ({
      //it takes the current details of the product(prevdetails)and creates a new set of details with an updatted formatted price
      ...prevDetails,
      price_details: {
        ...prevDetails.price_details,
        //amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
        //delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
        machine_price: formatCurrency(prevDetails.sales_rate),
      },
    }));
  };

  const formatCurrency = (value) => {
    //like to show in indian rupees it adds commas where needed and shows the right number of decimal place
    const fractionDigits = value % 1 !== 0 ? 2 : 0;
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value || 0);
  };

  const handleModal = async (status, obj) => {
    // Check if the user is logged in
    const loggedin = !!localStorage.getItem("userToken");
    if (!loggedin) {
      // If not logged in, set login portal to true and stop further execution
      setLoginPortal(true);
      return;
    }

    // If status is 'phone-modal', handle specific logic
    if (status === "phone-modal") {
      // Check logged in status again (though redundant as already checked above)
      const loggedin = !!localStorage.getItem("userToken");
      if (!loggedin) {
        // If not logged in, set login portal to true and stop further execution
        setLoginPortal(true);
        return;
      } else {
        // Navigate to a specific route with query parameters
        navigate(`/manageaddresspart`);
      }
    }

    // If status is truthy (not 'phone-modal'), show modal
    if (status) {
      setShowModal(status);
    } else {
      // If status is falsy, hide modal and perform additional actions
      setShowModal(false);
      const id = localStorage.getItem("id");
      console.log("obj------------>", obj);
      setBuymachineId(obj?.buymachine_id); // Set buy machine ID from obj parameter
      try {
        // Fetch product details using GraphQL query based on productId
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { itemId: productId },
        });
        setProductDETAILS(data?.inventoryItem?.response); // Update product details state
        updateFormattedPrice(); // Update formatted price based on new product details
      } catch (error) {
        // Handle errors if GraphQL query fails
        console.error("Error fetching product details:", error);
      }
    }
  };


  const handleBuyNow = async () => {
    // Log the details of the clicked product

    const productIdtoCart = productDETAILS?.[infokey]?.id;
    const productName = productDETAILS?.[infokey]?.name;
    const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
    const productimage =
      productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
    const salesPrice = productDETAILS?.[infokey]?.saleprice;

    const bucketId = getBucketId(selectedQuantity);

    const quantityprice = quantity;
    const quantitypricevc = selectedQuantity;


   
  const loggedin = !!localStorage.getItem('userToken');
    
  if (loggedin) {
      // Extract user details from localStorage
      const userToken = localStorage.getItem("userToken");
      const ompuserId = localStorage.getItem("id");
      const customer_first_name = localStorage.getItem("firstName");
      const customer_last_name = localStorage.getItem("lastName");
      const customer_contact_no = localStorage.getItem("number");
      const customer_email = localStorage.getItem("emailId");

      const itemList = [{
          item_id: productIdtoCart,
          quantity: quantitypricevc,
          bucket_id: bucketId
      }];
      
      console.log("Item list for API:", itemList);

      try {
          const response = await fetch("https://ompextension.origa.market/sparesapi/create_order", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  
              },
              body: JSON.stringify({
                  items: itemList,
                  ompuserId,
                  customer_first_name,
                  customer_last_name,
                  customer_contact_no,
                  order_status: "Buy",
                  order_ref_number: "",
                  customer_email,
                  input: {
                      city: "",
                      cityArea: "",
                      companyName: "",
                      country: "IN",
                      countryArea: "",
                      firstName: "",
                      lastName: "",
                      phone: "",
                      postalCode: "",
                      streetAddress1: "",
                      streetAddress2: "",
                  },
                  businessaddress: {
                      city: "",
                      cityArea: "",
                      companyName: "",
                      country: "IN",
                      countryArea: "",
                      firstName: "",
                      lastName: "",
                      phone: "",
                      postalCode: "",
                      streetAddress1: "",
                      streetAddress2: "",
                  },
                  gst_no: "",
              }),
          });
          console.log("Response status:", response.status);  
          const responseData = await response.json(); 
          console.log("Response data:", responseData); 

          const { 
              order_id: orderId, 
              total_quantity: totalQuantity, 
              subtotal: subtotttal, 
              tax_total: gst, 
              discount_total: taxes, 
              shipping_charge: shippingCharges, 
              total 
          } = responseData; // Destructure the needed fields
          
          console.log("Order ID:", orderId);
          console.log("Total Quantity:", totalQuantity);
          console.log("Subtotal:", subtotttal);
          console.log("GST:", gst);
          console.log("Taxes:", taxes);
          console.log("Shipping Charges:", shippingCharges);
          console.log("Total:", total);

          // Navigate to the next page with renamed states
          setLoading(true);
          setTimeout(() => {
              navigate('/manageaddresspart', { 
                  state: { 
                      itemId: productIdtoCart,
                      bucketId, 
                      quantity, 
                       orderId,
                      quantityTotal: totalQuantity,
                      subtotalAmount: subtotttal,
                      gstAmount: gst,
                      taxesAmount: taxes,
                      shippingFee: shippingCharges,
                      totalAmount: total
                  } 
              });
              window.location.reload();
          }, 200);

      } catch (error) {
          console.error("Error adding item to API cart:", error);
      }
  } else {
      // If not logged in, show the login portal
      setLoginPortal(true);
  }
};


  const breadcrumbsItems = [
    { name: "Tools and Consumables", link: "/store" },
    { name: productName, link: "/store" },

    // { name: "Spares", link: "/store" },
  ];
  const boldtitle = productDETAILS?.machine_details?.data?.product?.name;

  const handleInputChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue = numericValue.substring(0, 6); // Limit to 6 characters

    setDeliveryLocation(formattedValue);
    localStorage.setItem("deliveryLocation", formattedValue);

    if (formattedValue.length < 6) {
        // Open the modal if pincode length is less than 6
        handleSeeMore(true);
    } else {
        // Close the modal if pincode length is exactly 6
        handleSeeMore(false);
    }
};

const handlepinChange = (event) => {
  const value = event.target.value;
  const formattedValue = value.length < 6 ? "" : value.substring(0, 6); // Ensure it is exactly 6 characters

  setDeliveryLocation(formattedValue);
  localStorage.setItem("deliveryLocation", formattedValue);

  // Call checkDeliveryAvailability only if pincode length is exactly 6
  if (formattedValue.length === 6) {
      checkDeliveryAvailability(formattedValue);
  }
};

  

  const onSubmitHandler = () => {
    //the function is triggered when a user submits something related to a product
    console.log("product====>", productDETAILS?.machine_details?.data?.product);
    //It collects specific details about the product from productDetails and then call another function onWishListHandler
    const productId = productDETAILS?.machine_details?.data?.product?.id;
    const thumbnail =
      productDETAILS?.machine_details?.data?.product?.thumbnail?.url;
    const pricing =
      productDETAILS?.machine_details?.data?.product?.pricing?.priceRange?.start
        ?.gross?.amount;
    const Brands =
      productDETAILS?.machine_details?.data?.product?.attributes?.Brands;
    const category =
      productDETAILS?.machine_details?.data?.product?.category?.parent?.name;
    const subcategory =
      productDETAILS?.machine_details?.data?.product?.category?.name;
    onWishlistHandler(
      productId,
      thumbnail,
      pricing,
      Brands,
      category,
      subcategory
    );
  };

  //used to show whether a button is clicked or not like adding background color to purple when clicked
  const [clickedProductKey, setClickedProductKey] = useState(null);

  //Checks if productDETAILS is already an array
  //if productDETAILS is an array then productDetailsArray is set to productDETAILS
  //if productDETAILS is not an array then it is converted to an array
  const productDetailsArray = Array.isArray(productDETAILS)
    ? productDETAILS
    : [productDETAILS];
  const renderAttributes = () => {
    const attribute1 = productDETAILS?.[infokey]?.attributes?.attribute1;
    const attribute2 = productDETAILS?.[infokey]?.attributes?.attribute2;

    if (!attribute1 && !attribute2) {
      return null; // Hide the component if both attributes are not available
    }

    return (
      <div className="box-item">
        <div className="heading-600-18">
          {attribute1?.name || ""}{" "}
          {attribute2?.name ? `& ${attribute2.name}` : ""}
        </div>
        <div className="heading-400-14-12 light-txt">{renderButtons()}</div>
      </div>
    );
  };
  const renderButtons = () => {
    console.log("productDETAILS inside renderButtons :", productDETAILS);

    const productArray = Object.entries(productDETAILS)
      .filter(([key]) => key !== "price_details")
      .map(([_, value]) => value);

    if (productArray.length === 0) {
      console.log("No products to render buttons for.");
      return <p>No product variants available.</p>;
    }

    return productArray
      .map((product, index) => {
        if (!product) {
          console.log(`Product at index ${index} is undefined or null`);
          return null;
        }

        const attribute1 = product.attributes?.attribute1;
        const attribute2 = product.attributes?.attribute2;



        console.log("Attribute 1  : ",attribute1);
        

        if (!attribute1 && !attribute2) {
          return null;
        }

        return (
          <button
            key={product.id}
            className={`buttonclickednodvjbktor ${
              product.id === selectedItemId ? "btn-clicked" : ""
            }`}
            onClick={() => handlesizeandcolour(index)}
          >
            {attribute1 && `${attribute1.options?.name}`}
            {attribute1 && attribute2 && <br />}
            {attribute2 && `${attribute2.options?.name}`}
          </button>
        );
      })
      .filter(Boolean);
  };
  //useRef is a hook in React that allows you to create a reference to a value or a DOM element.

  //This ref is like a sticky note that will remember whether it’s the
  //first time the component has loaded or not.
  const isInitialLoad = useRef(true);

  useEffect(() => {
    //this is to say that when the page is first loaded show the firstItem as selected
    if (productDetailsArray && isInitialLoad.current) {
      // It’s perfect for tracking whether the component is loading for the first time.
      //This ensures the code runs only during the initial load and not on every update to productDetailsArray.

      const firstProductKey = Object.keys(productDetailsArray)[0]; //retrieve the first key
      if (firstProductKey) {
        setClickedProductKey(firstProductKey); // Set default button selection to the first product
      }
      isInitialLoad.current = false;
    }
  }, [productDetailsArray]);

  const handlesizeandcolour = (index) => {
    const productArray = Object.values(productDETAILS).filter(
      (item) => typeof item === "object" && item.id
    );
    const selectedProduct = productArray[index];

    setinfokey(index);
    setQuantity(1);
    setSelectedQuantity(1);
    setSelectedItemId(selectedProduct.id);
    setSelectedProduct(selectedProduct);
  };

  const [clickedPriceItem, setClickedPriceItem] = useState(null); //to show which bucket range is clicked
  const [mrpSize,setmrpSize]=useState(null);
  const [bucketPrice, setBucketPrice] = useState(null); //used to store the price of the current bucket
  const [bucketSize, setBucketSize] = useState(null);
  const [bucketlabel,setBucketLabel] = useState(null);
  useEffect(() => {
    //this will run everytime when productDETAILS or infokey changes.
    if (productDETAILS && infokey !== undefined) {
      //Ensures that both necessary values are available before proceeding.
      const product = productDETAILS[infokey]; //product now holds the details of the currently selected product.
      setSelectedProduct(product); //Updates the state selectedProduct with the product object retrieved
      //Check if product is present acnd check atleast one element is present
      if (
        product &&
        Array.isArray(product.price_list) &&
        product.price_list.length > 0
      ) {
        setClickedPriceItem(0); // Set default price range to the first item
        setBucketPrice(product.price_list[0].price); // Set default bucket price
        setBucketSize(product.price_list[0].bucket_size);
        setBucketLabel(product.price_list[0].price_label)
        setmrpSize(product.price_list[0].mrp);
      } else {
        //if no valid price then it resets clickedPriceItem and bucketPrice to null.
        setClickedPriceItem(null);
        setBucketPrice(null);
        setBucketSize(null);
        setBucketLabel(null);
        setmrpSize(null)
      }
    }
  }, [productDETAILS, infokey]); //dependency array specifies that array should only run whenever the productDETAILS or infokey changes.

  const renderPriceList = () => {
    if (selectedProduct && Array.isArray(selectedProduct.price_list)) {
      return (
        <div>
          {selectedProduct.price_list.length > 0 ? (
            selectedProduct.price_list.map((priceItem, index) => (
              <button
                key={index}
                className={`buttonclickednodvjbktor ${
                  clickedPriceItem === index ? "btn-clicked" : ""
                }`}
                onClick={() => handlePriceClick(priceItem, index)}
              >
                {priceItem.price_label}
                <br />₹{priceItem.price}/Ltr
                <br />
                <span
                  className="priceItemmrp"
                  style={{color:"grey",fontSize:"16px",fontWeight:"bolder"}}
                >
                  <s>₹{priceItem.mrp}/Ltr</s>
                  <br />
                  <span
                    className="offerrrr"
                    style={{color:"green",fontSize:"16px",marginLeft:"5px"}}
                  >
                     {calculateDiscount(priceItem.mrp, priceItem.price)}% off
                  </span>
                  <br />
                </span>
              </button>
            ))
          ) : (
            <p>No price list available</p>
          )}
        </div>
      );
    }
    return <p>Loading...</p>;
  };
  const handlePriceClick = (priceItem, index) => {
    console.log("Clicked price item:", priceItem);
    setClickedPriceItem(index); //This helps in highlighting or selecting the clicked price item.
    setBucketPrice(priceItem.price); //This updates the displayed price to match the selected price item.
    setBucketSize(priceItem.bucket_size);
    setEndQuantity(priceItem.end_quantity);
    setBucketLabel(priceItem.price_label);
    setmrpSize(priceItem.mrp);


    console.log("Bucket Price : --------->", bucketPrice);
    console.log("End Quantity : ", endQuantity);
    console.log("Bucket Price label : ",bucketlabel);
    console.log("Mrp Price : --------->", mrpSize);
    if (priceItem.start_quantity) {
      setSelectedQuantity(priceItem.start_quantity);
    }
  };
  const [displayText, setDisplayText] = useState("");

  // The handlePriceClick function
  // const handlePriceClick = (priceItem, index) => {
  //     console.log("Clicked price item:", priceItem);
  //     setClickedPriceItem(index); // This helps in highlighting or selecting the clicked price item.
  //     setBucketPrice(priceItem.price); // This updates the displayed price to match the selected price item.

  //     // Update selectedQuantity and displayText based on priceItem.start_quantity
  //     if (priceItem.start_quantity) {
  //         if (priceItem.start_quantity >= 11) {
  //             setSelectedQuantity(1);
  //             setDisplayText("Barrel : 210lts : Bucket : 20Ltrs");
  //         } else {
  //             setSelectedQuantity(priceItem.start_quantity);
  //             setDisplayText(""); // or any other default text you prefer
  //         }
  //     }
  // };

  useEffect(() => {
    //runs everytime when selectedQuantity or selectedProduct changes
    if (selectedProduct && Array.isArray(selectedProduct.price_list)) {
      //checks if the price_list exists
      const applicablePriceItem = selectedProduct.price_list.find(
        (
          item //searches for the first item that matches the logic in the below
        ) =>
          selectedQuantity >= item.start_quantity && //checks the selectedquantity is within the bucket range
          (item.end_quantity === null || selectedQuantity <= item.end_quantity)
      );

      if (applicablePriceItem) {
        //if valid price item is found then
        setBucketPrice(applicablePriceItem.price); //set the price range to that butcketPrice
        setBucketSize(applicablePriceItem.bucket_size);
        setEndQuantity(applicablePriceItem.end_quantity);
        setClickedPriceItem(
          selectedProduct.price_list.indexOf(applicablePriceItem)
        ); //used to hightlight which priceItem is currently selected :
        setmrpSize(applicablePriceItem.mrp);
      }
    }
  }, [selectedQuantity, selectedProduct]);

  const handleQuantityChange = (increment) => {
    setSelectedQuantity((prevQuantity) => prevQuantity + increment);
  };

  const getPriceLabel = () => {
    if (quantity >= 11) {
      return "Barrel Price:";
    } else {
      return "Bucket Price:";
    }
  };
  const calculateTotalValue = () => {
    // Calculate for barrel price when quantity is 11 or more
    if (selectedQuantity >= 11) {
      return bucketSize * bucketPrice;
    } else {
      // Calculate for bucket price for quantities less than 11
      return bucketSize * bucketPrice * selectedQuantity;
    }
  };


  // Calculate total value
  const totalvalue = calculateTotalValue();

  useEffect(() => {
    console.log("Quantity changed:", selectedQuantity);
    console.log("Total value is:", totalvalue);
  }, [selectedQuantity, totalvalue]);
    

  const bucketvalueof = getPriceLabel();


  const headingforseo = {
    heading: `   ${productDETAILS?.[infokey]?.brand}  ${productDETAILS?.[infokey]?.name }`,
  };
  
  useEffect(() => {
    console.log("Heading for Seo:", headingforseo.heading); // Accessing the heading value directly
  }, [headingforseo.heading]);
  return (
    <>
    <Helmet>
      <title>{headingforseo.heading}</title> {/* Access the heading as a string */}
      <meta name="keywords" content={headingforseo.heading} />
    </Helmet>
      {loading && <Loader />}
      {QuationModel && (
        <>
          <MachinePurchaseModal SetQuationModel={SetQuationModel} />
        </>
      )}
      {showModal === "image-modal" && (
        <div className="img-wrap-main">
          {showModal === "image-modal" && (
            <ImageSlider modalAction={handleModal} />
          )}
        </div>
      )}
      {/* {showModal === "video" && (
                <VideoModal modalAction={handleModal} video={productDETAILS.product_video[0]} />
            )} */}
      {/* {showModal === "phone-modal" && (
                <PhoneModal modalAction={handleModal} productId={productId} buymachineId={buymachineId} />
            )} */}
      {showModal === "date-modal" && (
        <DateModal
          productId={productId}
          productDETAILS={productDETAILS}
          buyMachineId={buymachineId}
          modalAction={handleModal}
        />
      )}
      {showModal === "detailed-inspection" && (
        <DetailedInspection modalAction={handleModal} productId={productId} />
      )}
       {seeMore && (
                <DeliveryLocation modalAction={handleSeeMore}  pinvalue={onGetPinCodeHandler} />
            )}
      <div className="container-fluid col-cust">
        <div className="max-container my-5">
          <div className="top-wrap-main">
            <div>
              <Breadcrumbs
                items={breadcrumbsItems}
                boldtitle={boldtitle}
                backnavi={() => navigate("/store")}
              />
            </div>
            <div className="btn-wrap">
                                <button  onClick={() => handleSeeMore(true)} className=" dsvjfsj">
                                    {deliveryLocation ? `Delivery Location : ${deliveryLocation}` : 'Set Delivery Location'}
                                </button>
                            </div>
            {/* <div className="form-group">
            <input
  type="text"
  value={deliveryLocation}
  onChange={handleInputChange}
  onBlur={handlepinChange}
  placeholder="Enter Pincode"
  onClick={() => deliveryLocation.length < 6 && handleSeeMore(true)} // Only open modal if pincode is < 6
/>



              {locationIcon({ width: 24, height: 24 })}
              
            </div> */}
          </div>
          <div className="name-wrap-main">
            <div className="heading-wrap">
              <div className="heading-600-24 heading-600-24-20 heading-600-24-16 heading-600-24-14">
              {productDETAILS?.[infokey]?.brand} - {productDETAILS?.[infokey]?.name}
              </div>
              {/* Lubricants-Blue/50 */}
            </div>
            {/* <div className="people heading-400-14-12">
              <span
                onClick={() => {
                  setIsFill("#73509E");
                  setIsStorke("#73509E");
                }}
              >
                {productDETAILS?.wishlist_details?.wishlist}&nbsp;&nbsp;
                {heartIcon({
                  width: 25,
                  onClick: () => onSubmitHandler(),
                  height: 25,
                  fill: isFill,
                  stroke: isStorke,
                })}
              </span>
            </div> */}
          </div>
        </div>
      </div>

      <div className="product-img-wrap-main">
        {" "}
        {/*three images */}
        <Slider ref={sliderProductRef} {...options}>
          {productDETAILS?.[infokey]?.images?.productImages.map(
            (image, index) => (
              <div key={index} className="product">
                <img className="product-img" src={image.url} alt="" />
                {image.button_type === "view_all" ? (
                  <div
                    className="drag"
                    onClick={() => handleModal("image-modal")}
                  >
                    View all
                  </div>
                ) : image.button_type === "3d" ? (
                  <button className="framre">
                    {framre3dIcon({ width: 30, height: 30 })}
                  </button>
                ) : (
                  <></>
                )}
              </div>
            )
          )}
        </Slider>
      </div>
      {product.map(
        (prodcut) =>
          prodcut.is_product_info && (
            <div className="container-fluid col-cust">
              <div className="max-container my-4">
                <div className="box-wrap-main">
                <div className="box-inner">
  {prodcut.tabs_section.map(
    (product, index) =>
      product.is_tabs_section && (
        <div key={index} className="box-item">
          <div className="tabs-wrap">
            {Object.keys(
              productDETAILS?.[infokey]?.productDetails ?? {}
            ).map((key, index) => (
              <button
                key={key}
                onClick={() => handleActiveTab(key)}
                className={
                  activeTab === key
                    ? "active tab-btn heading-600-14 heading-600-14-12"
                    : "tab-btn heading-600-14 heading-600-14-12"
                }
                type="button"
              >
                {key}
              </button>
            ))}
          </div>
          <div className="items-wrap">
            {Object.keys(
              productDETAILS?.[infokey]?.productDetails ?? {}
            ).map((key, index) => (
              <React.Fragment key={key}>
                {activeTab === key && (
                  <>
                    {Object.entries(
                      productDETAILS?.[infokey]?.productDetails[key] ?? {}
                    ).length === 0 ? (
                      <div className="item">
                        <span className="heading-400-14-12 light-txt">
                          No Data
                        </span>
                      </div>
                    ) : (
                      <>
                        {Object.entries(
                          productDETAILS?.[infokey]?.productDetails[key]
                        ).map(([subKey, value]) => (
                          <div key={subKey} className="item">
                            <span className="heading-400-14-12 light-txt">
                              {subKey}
                            </span>
                            <span className="heading-500-16">{value}</span>
                          </div>
                        ))}

                        {/* Add Static Bucket Field Here */}
                        <div className="item">
                          <span className="heading-400-14-12 light-txt">
                            Bucket
                          </span>
                          <span className="heading-500-16">
                            {bucketSize}Ltrs
                          </span>
                        </div>
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )
  )}
</div>

                  <div className="box-inner">
                    {renderAttributes()}
                    {/* <div className="box-item">
                      <div className="heading-600-18">Size & Colour</div>
                      <div className="heading-400-14-12 light-txt">
                        {renderButtons()}
                      </div>
                    </div> */}

                    {/* <div className="box-item">
                      <div className="heading-600-18">Quantity HardCoded</div>
                      <div className="heading-400-14-12 light-txt">
          <button
            className="btn btn-light m-1"
            onClick={() => handleQuantityClick(1)}
          >
            Qty 1-2
            <br />
            ₹{productDETAILS?.[infokey]?.saleprice}
          </button>
          <button
            className="btn btn-light m-1"
            onClick={() => handleQuantityClick(2)}
          >
            Qty 2-3
            <br />
            ₹{productDETAILS?.[infokey]?.saleprice * 2}
          </button>
          <button
            className="btn btn-light m-1"
            onClick={() => handleQuantityClick(3)}
          >
            Qty 3-4
            <br />
            ₹{productDETAILS?.[infokey]?.saleprice * 3}
          </button>
          <button
            className="btn btn-light m-1"
            onClick={() => handleQuantityClick(4)}
          >
            Qty 4-5
            <br />
            ₹{productDETAILS?.[infokey]?.saleprice * 4}
          </button>
        </div>
                     
                    </div> */}

                    {/* <div className="box-item">
                      <div className="heading-600-18">Quantity </div>
                      <div className="heading-400-14-12 light-txt">
                        {renderQuantityLogic()}
                        <div></div>
                      </div>
                    </div> */}

                    {/* <div className="box-item">
                      <div className="heading-600-18">Quantity :  </div>
                      <div className="heading-400-14-12 light-txt">
                        {renderQuantitymmyLogic()}
                        <div></div>
                      </div>
                    </div> */}

                    <div className="box-item">
                      <div className="heading-600-18">Quantity : </div>
                      <div className="heading-400-14-12 light-txt">
                        {renderPriceList()}
                        <div></div>
                      </div>
                    </div>

                    {/* <div className="box-item">
                                        <div className="heading-600-18">Size & Colour</div>
                                        <div className="heading-400-14-12 light-txt">
                                            {productDETAILS?.map((product, index) => (
                                                <button className="btn btn-light m-1">
                                                    {product.attributes?.[`attribute${index}`]?.name}:{product.attributes?.[`attribute${index}`]?.options?.name}
                                                </button>

                                            ))
                                            }
                                            {productDETAILS?.[0]?.attributes?.attribute2?.name && (
                                                <button className="btn btn-light m-1">
                                                    {productDETAILS?.[0]?.attributes?.attribute2?.name}: {productDETAILS?.[0]?.attributes?.attribute2?.options?.name}
                                                </button>
                                            )}
                                        </div>
                                    </div> */}

                    {prodcut.price_section.map(
                      (price, index) =>
                        price.is_price_section && (
                          <div key={index} className="box-item">
                            <div className="heading-600-18">
                              {price.heading}
                            </div>
                            <div className="heading-400-14-12 light-txt">
                              <CheckPurple /> {price.desc}
                            </div>
                            <div className="heading-400-14-12 light-txt">
                              {" "}
                              <CheckPurple /> {price.desc1}
                            </div>
                            <button
                              onClick={onShowPortal}
                              type="button"
                              className="box-item-btn"
                            >
                              {price.btn_text}
                            </button>
                          </div>
                        )
                    )}

                    {/* <div className="box-item">
                            <div className="heading-600-18">
                              Quantity
                            </div>
                            <div className="heading-400-14-12 light-txt">
                
                            </div>
                          </div> */}

                    {prodcut.token_section.map(
                      (token, index) =>
                        token.is_token_section && (
                          <div key={index} className="box-item">
                            <div className="heading-600-18">
                              {token.heading}
                            </div>
                            <div className="heading-400-14-12 light-txt">
                              {" "}
                              <PricesCompetitive /> {token.desc}{" "}
                              <CallCenterIcon />
                              {token.desc1} <InvoiceIcon /> {token.desc2}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
      )}

      {!isProductPriceMore()
        ? product.map((prodcut) => (
            <div className="container-fluid col-cust" key={prodcut.productId}>
              <div className="max-container my-4 benefits-main">
                {prodcut.is_benefits && (
                  <>
                    {prodcut.head.map((head, index) => (
                      <div className="head" key={index}>
                        <div className="heading-wrap">
                          <div className="heading-600-32 heading-600-32-20 text-left">
                            {head.heading}
                          </div>
                          <div className="heading-400-14-12 light-txt pt-3">
                            {head.desc}
                          </div>
                        </div>
                        <button
                          onClick={() => handleModal("detailed-inspection")}
                          className="box-btn heading-600-14 heading-600-14-12"
                        >
                          {head.btn_text}
                        </button>
                      </div>
                    ))}
                    <div className="benefits-content">
                      <div className="benefits-content-head">
                        <div className="slider-button-wrap">
                          <button
                            className="slider-button"
                            onClick={previousSliderBenefitsRef}
                          >
                            {leftArrowIcon({ width: 24, height: 24 })}
                          </button>
                          <button
                            className="slider-button"
                            onClick={nextSliderBenefitsRef}
                          >
                            {rightArrowIcon({ width: 24, height: 24 })}
                          </button>
                        </div>
                      </div>
                      <Slider
                        ref={sliderBenefitsRef}
                        {...BenefitsOptions}
                        className="cust-slider"
                      >
                        <div className="item">
                          <div className="progress-circle">
                            <svg>
                              <circle cx="65" cy="65" r="60"></circle>
                              <circle
                                className="main-circle"
                                cx="65"
                                cy="65"
                                r="60"
                                style={styleHandle(58, "#D6F518")}
                              ></circle>
                            </svg>
                            <div className="score-wrap">
                              <div className="heading-600-20 heading-600-20-16 t-a-c">
                                04/05
                              </div>
                              <div className="heading-600-14 heading-600-14-12 light-txt t-a-c">
                                Good
                              </div>
                            </div>
                          </div>
                          <div className="title t-a-c">
                            <span>
                              External Visual Condition
                              <div className="svg-wrap">
                                {infoIcon({
                                  width: 24,
                                  height: 24,
                                  onClick: () =>
                                    handleModal(
                                      showModal === "external-visual"
                                        ? false
                                        : "external-visual"
                                    ),
                                })}
                                {showModal === "external-visual" && (
                                  <span className="hidden-txt hidder-txt-first">
                                    The external Machine Condition is rated as
                                    4/5 which means there are barely a few dents
                                    and scratches.
                                  </span>
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="item">
                          <div className="progress-circle">
                            <svg>
                              <circle cx="65" cy="65" r="60"></circle>
                              <circle
                                className="main-circle"
                                cx="65"
                                cy="65"
                                r="60"
                                style={styleHandle(100, "#077D55")}
                              ></circle>
                            </svg>
                            <div className="score-wrap">
                              <div className="heading-600-20 heading-600-20-16 t-a-c">
                                05/05
                              </div>
                              <div className="heading-600-14 heading-600-14-12 light-txt t-a-c">
                                Great
                              </div>
                            </div>
                          </div>
                          <div className="title t-a-c">
                            <span>
                              Static Geometric Test
                              <div className="svg-wrap">
                                {infoIcon({
                                  width: 24,
                                  height: 24,
                                  onClick: () =>
                                    handleModal(
                                      showModal === "geometric-test"
                                        ? false
                                        : "geometric-test"
                                    ),
                                })}
                                {showModal === "geometric-test" && (
                                  <span className="hidden-txt">
                                    The external Machine Condition is rated as
                                    4/5 which means there are barely a few dents
                                    and scratches.
                                  </span>
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="item">
                          <div className="progress-circle">
                            <svg>
                              <circle cx="65" cy="65" r="60"></circle>
                              <circle
                                className="main-circle"
                                cx="65"
                                cy="65"
                                r="60"
                                style={styleHandle(28, "#E86427")}
                              ></circle>
                            </svg>
                            <div className="score-wrap">
                              <div className="heading-600-20 heading-600-20-16 t-a-c">
                                02/05
                              </div>
                              <div className="heading-600-14 heading-600-14-12 light-txt t-a-c">
                                Workable
                              </div>
                            </div>
                          </div>
                          <div className="title t-a-c">
                            <span>
                              Electric & Pneumatic Condition
                              <div className="svg-wrap">
                                {infoIcon({
                                  width: 24,
                                  height: 24,
                                  onClick: () =>
                                    handleModal(
                                      showModal === "pneumatic-condition"
                                        ? false
                                        : "pneumatic-condition"
                                    ),
                                })}
                                {showModal === "pneumatic-condition" && (
                                  <span className="hidden-txt">
                                    The external Machine Condition is rated as
                                    4/5 which means there are barely a few dents
                                    and scratches.
                                  </span>
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="item">
                          <div className="progress-circle">
                            <svg>
                              <circle cx="65" cy="65" r="60"></circle>
                              <circle
                                className="main-circle"
                                cx="65"
                                cy="65"
                                r="60"
                                style={styleHandle(42, "#F6CF41")}
                              ></circle>
                            </svg>
                            <div className="score-wrap">
                              <div className="heading-600-20 heading-600-20-16 t-a-c">
                                03/05
                              </div>
                              <div className="heading-600-14 heading-600-14-12 light-txt t-a-c">
                                Fair
                              </div>
                            </div>
                          </div>
                          <div className="title t-a-c">
                            <span>
                              Machine Usage History
                              <div className="svg-wrap">
                                {infoIcon({
                                  width: 24,
                                  height: 24,
                                  onClick: () =>
                                    handleModal(
                                      showModal === "machine-usage"
                                        ? false
                                        : "machine-usage"
                                    ),
                                })}
                                {showModal === "machine-usage" && (
                                  <span className="hidden-txt">
                                    The external Machine Condition is rated as
                                    4/5 which means there are barely a few dents
                                    and scratches.
                                  </span>
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))
        : product.map(
            (prodcut) =>
              prodcut.is_benefits_layout_2 && (
                <div className="container-fluid col-cust">
                  <div className="max-container my-4 benefits-main-2">
                    {prodcut.head.map((head, index) => (
                      <div className="head" key={index}>
                        <div className="heading-wrap">
                          <div className="heading-600-32 heading-600-32-20 text-left">
                            {head.heading}
                          </div>
                          <div className="heading-400-14-12 light-txt">
                            {head.desc}
                          </div>
                        </div>
                        <button
                          onClick={() => handleModal("detailed-inspection")}
                          className="box-btn heading-600-14 heading-600-14-12"
                        >
                          {head.btn_text}
                        </button>
                      </div>
                    ))}
                    <div className="benefits-content-2">
                      {prodcut.benefits_layout_2_items.map((benefit, index) => (
                        <div key={index} className="item">
                          <div className="title">
                            {visualIcon({ width: 24, height: 24 })}
                          </div>
                          <div className="content-section">
                            <div className="heading-600-16">
                              {benefit.title}
                            </div>
                            <div className="desc heading-400-16-14 light-txt">
                              {benefit.desc}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
          )}
      <Footer />
      <div className="call-to-action-wrap-main">
        <div className="container-fluid col-cust">
          <div className="max-container my-0 benefits">
            <div className="call-to-action-main">
              <div className="text-contain">
              {productDETAILS?.[infokey]?.brand}
                <div className="heading-600-24 heading-600-24-20 heading-600-24-16">
                  {productDETAILS?.[infokey]?.name}
                </div>
                {/* <div className="light-txt">{bucketSize}Ltrs</div> */}

              </div>
              <div>
              {deliveryAvailable ? (
     <>
                  <div className="light-txt">
    {/* Conditionally show "Barrel Price" if quantity is 11, otherwise show "Bucket Price" */}
    {bucketSize}L   (Pack of {selectedQuantity}) <br></br>
   {/* {bucketlabel} */}
   <span style={{ fontWeight: "bold", color: "black" }}>
      <span  style={{ fontWeight: "bold", color: "black" ,fontSize:"1.25rem"}} > ₹{totalvalue} </span>
     <div  style={{ fontWeight: "bold", color: "black" ,fontSize:"1.1rem"}} >
     <s style={{ fontWeight: "600", color: "#3c3d40" ,fontSize:"1.1rem"}}>₹{mrpSize}</s>   (₹{bucketPrice !== null ? bucketPrice : "..."}/Ltr)
     </div>
     <span style={{ color: "green", marginLeft: "5px", fontSize: "18px"}}>
     {calculateDiscount(selectedProduct?.price_list[0]?.mrp, selectedProduct?.price_list[0]?.price)}% off
                                </span>
     
    </span>
  </div>
     {/* <div className="heading-600-24 heading-600-24-20 heading-600-24-16">
                  ₹{productDETAILS?.[infokey]?.saleprice * quantity} / Lt
                  ₹{prldvsjice} /Lt
                  ₹{bucketPrice || 'Loading...'}/Lt₹
                  {bucketPrice !== null ? bucketPrice : "..."} / Ltr
                </div> */}
     </>
     ) : (
	 <div></div>
    
     )}

                {/* <div className="light-txt">Selling Price : {}</div> */}
                {/* <div className="light-txt"><span>or </span>₹{TenureAmount}/month</div> */}
              </div>

              <div className="btns-wrap">
              {deliveryAvailable ? (
     <>
     <div className="quantity-selector">

                  <button
                    onClick={() => handleQuantityChange(-1)}
                    className="box-item-btn"
                    disabled={selectedQuantity <= 1} // Disable decrease if quantity is 1
                  >
                    -
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span className=" lejnrfb">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {selectedQuantity}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
  
                  <button
                    onClick={() => handleQuantityChange(1)}
                    disabled={endQuantity === null}
                    className="box-item-btn"
                  >
                    +
                  </button>
                </div>
     </>
     ) : (
    <div></div>
     )}
               

 {deliveryAvailable ? (
     <>
     <div className="btns-group">
	  <button
                    onClick={() =>
                      handleCart(
                        productIdtoCart,
                        selectedQuantity,
                        bucketPrice,
                        quantity,
                        bucketSize,
                        productName,
                        salePrice,
                        bucketPrice,
                        endQuantity
                      )
                    }
                    type="button"
                    disabled={isDisabled}
                    className="box-item-btn"
                  >
                    Add to Cart
                  </button>
               
                  <button
                    onClick={() =>
                      handleBuyNow(
                        productIdtoCart,
                        quantity,
                        selectedQuantity,
                        bucketPrice
                      )
                    }
                    type="button"
                    className="box-item-btn buy-now"
                  >
                    Buy Now
                  </button>

                  <button
                    onClick={() =>
                      handleRaiseAQuotation(
                        productIdtoCart,
                      )
                    }
                    type="button"
                    className="inbutton box-item-btn"
                  >
                    Request quotation
                  </button>
	 </div>
     </>
     ) : (
    <div className="btns-group">
	  <button
                    onClick={() =>
                      handleRaiseAQuotation(
                        productIdtoCart,
                      )
                    }
                    type="button"
                    className="inbutton box-item-btn"
                  >
                    Request quotation
                  </button>
	 </div>
     )}

{showPopup && (
      <div className="popup-overlay">
        <div className="popup-banner">
          <div className="popup-content">
            <QuotationPopup
              productDetails={productDETAILS}
              
              onSubmit={handleSubmit}
            />
            <div className="close-popup" onClick={closePopup2}>
              {closeIcon({ width: 14, height: 14 })}
            </div>
          </div>
        </div>
      </div>
    )}

                {showThanksPopup && (
                  <div className="  popup-overlay ">
                    <div className="popup-banner ">
                      <div
                        className="popup-content "
                        style={{ backgroundColor: "#d2eff1" }}
                      >
                        <div
                          className="differentthankspopupcontnet"
                          style={{ backgroundColor: "white" }}
                        >
                          <h3 className="thanksforyourresponse">
                            Thank you for your quotation request!
                          </h3>
                          <p className="wewillcontactu">
                            We'll get back to you soon
                          </p>
                          <br></br>
                          <div
                            className="close-popup"
                            onClick={() => setShowThanksPopup(false)}
                          >
                            {closeIcon({ width: 14, height: 14 })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loginPortal && <LoginModel onHide={onHidePortal} />}
    </>
  );
};
export default ViewSpareProduct;
